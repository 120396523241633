import { ArBlogPost } from 'src/components/ArBlogPost'
import { ArSeo } from 'src/components/ArSeo'
import { BlogPostBySlugQuery } from 'gatsby-graphql'
import React from 'react'
import { graphql } from 'gatsby'
import { ContentfulLivePreviewProvider } from '@contentful/live-preview/react'

const BlogPostPage = ({ data }: { data: BlogPostBySlugQuery }) => {
  return (
    <ContentfulLivePreviewProvider locale="en-US">
      <ArSeo post={data.post} image={data?.post?.sharingCardImage?.file?.url || undefined} />
      <ArBlogPost data={data} />
    </ContentfulLivePreviewProvider>
  )
}

export default BlogPostPage

export const query = graphql`
  query BlogPostBySlug($slug: String!) {
    post: contentfulPost(slug: { eq: $slug }) {
      id
      title {
        title
      }
      sharingCardImage {
        file {
          url
        }
      }
      body {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            fixed(width: 1600) {
              width
              height
              src
              srcSet
            }
          }
        }
      }
      description {
        description
      }
      featuredImage {
        file {
          url
        }
        title
      }
      author {
        id
        name
        profilePhoto {
          file {
            url
          }
        }
      }
      isPressRelease
      category {
        title
      }
      date
    }
    featuredArticles: allContentfulPost(
      limit: 3
      filter: { featured: { eq: true }, slug: { ne: $slug } }
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          id
          slug
          title {
            title
          }
          description {
            description
          }
          author {
            id
            name
            profilePhoto {
              file {
                url
              }
            }
          }
          category {
            title
          }
          isPressRelease
          date
        }
      }
    }
  }
`
